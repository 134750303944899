<script lang="ts">
	export let href = '';
	export let text = '';
	import { cn } from "src/utils/cn";
	
</script>

<a class={cn('block text-neutral-500', `${$$props.class}`)} {href}>
	{text}
</a>
